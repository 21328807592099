<script lang="jsx">
import { transformImg, isString } from '@shein/common-function'
import ClientOnly from 'vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter'
import { exposeProductItem, clickProductItem } from './analysis'
import ProductItemImg from './components/ProductItemImg'
import ProductItemPrices from './components/ProductItemPrices'
import ProductItemPricesMy from './components/ProductItemPricesMy'
import ProductItemColorSwiper from './components/ProductItemColorSwiper'
import ProductItemSoldout from './components/ProductItemSoldout'
import ProductItemWishlistGroup from './components/ProductItemWishlistGroup'
import ProductItemImgBottom from './components/ProductItemImgBottom'
import ProductItemImgBottomRight from './components/ProductItemImgBottomRight'
import ProdcutItemFeedback from './components/ProdcutItemFeedback'
import ProdcutItemFeedbackGuide from './components/ProdcutItemFeedbackGuide'
import ProductItemOperationPanel from './components/ProductItemOperationPanel'
import ProductItemStarComment from './components/ProductItemStarComment'
import ProductItemRankingList from './components/ProductItemRankingList'
import ProductItemStarRatingWithLabels from './components/ProductItemStarRatingWithLabels'
import ProductItemBottomLabels from './components/ProductItemBottomLabels'
import ProductItemGoodsName from './components/ProductItemGoodsName'
import ItemDecisionAttr from './components/ItemDecisionAttr.vue'
import ProductItemNewFlashPrice from './components/ProductItemNewFlashPrice'
import { getProductDetailUrl, getImageRatio } from '../../../goods_detail/utils/common'
import { controlItemLabels } from './components/ItemLabelsControl.jsx'
import { clickAddBagAna } from './analysis'
import { addProductDetailBfCache, getStarVisible, controlBeltPrice } from './js/utils'
import { markPoint } from 'public/src/services/mark/index.js'

import { ItemLoading as SItemLoading } from '@shein/sui-mobile'
import PricePlaceHolder from 'public/src/pages/components/ccc/base/PricePlaceholder.vue'

daEventCenter.addSubscriber({ modulecode: '2-3' })
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-10-4' })
daEventCenter.addSubscriber({ modulecode: '1-10-2' })

/**
 * 2 一行二图
 * 2.3 一行2.3图
 * 2.5s 一行2.5图（小图，参考实时反馈轮播列表）
 * 2.9 一行2.9图
 * 3 一行三图
 * 3l 一行三图(宽版)
 * 3.3 一行3.3图 （参考：首页闪购）
 * 3.3l 一行3.3图(宽版)
 *  */
const columnDesignWidthMap = {
  '2': 338,
  '2.3': 300,
  '2.5s': 128,
  '2.9': 236,
  '3': 223,
  '3l': 231,
  '3s': 202,
  '3.3': 210,
  '3.3l': 222,
  '3.5': 182,
}

export default {
  name: 'SProductItem',
  components: {
    ClientOnly,
    SItemLoading,
    ProductItemImg,
    ProductItemSoldout,
    ProductItemWishlistGroup,
    ProductItemImgBottom,
    ProductItemImgBottomRight,
    ProductItemColorSwiper,
    ProductItemPrices,
    ProductItemPricesMy,
    ProdcutItemFeedback,
    ProdcutItemFeedbackGuide,
    ProductItemOperationPanel,
    ProductItemStarComment,
    ProductItemRankingList,
    ProductItemStarRatingWithLabels,
    ProductItemBottomLabels,
    ProductItemGoodsName,
    ItemDecisionAttr,
    ProductItemNewFlashPrice,
  },
  props: {
    /**
     * 其他
     * @param {Object} urlQuery 需要拼接在跳转链接上的参数
     * @param {Number} longClickTime 长按触发的时间 默认：1000，即1s
     *
     * 埋点相关信息
     * @param {Object} sa 自有埋点
     *    @param {Object} activity_from
     * @param {Object} ga ga埋点
     *    @param {String} code 埋点code
     * @param {String} itemDAEventClickId 点击埋点ID，默认2-3-1
     * @param {String} itemDAEventExposeId 曝光埋点ID，默认2-3-2
     * @param {Boolean} useOwnClickExposeAnalysis 是否使用自己的点击，曝光埋点
     * @param {Boolean} useOwnClickAddBagAnalysis 是否使用自己的点击加车icon埋点
     *
     * @param {String} colorSetPresentedType 图片右下角横竖色块
     * 
     * 禁止项
     * @param {Boolean} disableMainimgJump 禁止点击主图和标题跳转商详, 透传事件clickItem
     * @param {String} spaRouterJumpType SPA 跳转方式，默认 push
     * 
     * 隐藏项
     * @param {Boolean} hideFlashSaleIcon 隐藏闪购图标
     * @param {Boolean} hideSoldOut 隐藏售罄
     * @param {Boolean} hideNormalPrices 隐藏普通价格（售卖价，零售价）
     * @param {Boolean} hideRetailPrice 隐藏零售价（划线价）
     * @param {Boolean} hideBottomCccTspBadge 隐藏商品底部ccc-tsp标签
     * @param {Boolean} hideDiscountBadge 隐藏左上角折扣标签
     * @param {Boolean} hideIconBeforePrice 隐藏价格前的各种促销图标
     * @param {Boolean} hideS3VipPrice 隐藏s3 vip价格
     * @param {Boolean} searchFiltersWithoutSellingPoint 隐藏分布式筛选中的卖点
     *
     * 展示项
     * @param {Boolean} showPlusSize 显示大码
     * @param {Boolean} showMultiColor 显示色块
     * @param {Boolean} showMultiColorIcon 显示色块icon（图片右下角）
     * @param {Boolean} showPromotion 显示促销信息
     * @param {Boolean} showLikeBtn 显示收藏按钮
     * @param {Boolean} showAddBagBtn 加车按钮（展示收藏按钮时，位置在价格右侧，否则在图片左下角）
     * @param {Boolean} showAddBagAfterGoodsPrice 价格后，快速加车icon
     * @param {Boolean} addBagToDetail 钩子价格组件根据 abt 处理点击加购跳转 商详 / 加购
     * @param {Boolean} showBadge 显示ccc-tsp标签（左上角，左下角，商品底部）
     * @param {Boolean} showTitle 显示标题
     * @param {Boolean} showTitleTwoLine 显示两行标题
     * @param {Boolean} showAddToBoard 是否展示加组浮窗 （点击收藏icon后出现）
     * @param {Boolean} showSellingPoint 显示卖点角标
     * @param {Boolean} showSeriesBrand 品牌系列
     * @param {Boolean} showVideoIcon 视频icon
     * @param {Boolean} showColorSellOut 显示售罄色块
     * @param {Boolean} showSpuImg 显示spu图
     * @param {Boolean} showLocalSeller 本地发货标签
     * @param {Boolean} showQuickShip 快速发货标签
     * @param {Boolean} showLeftStock 剩余库存数量, 0-10(不含首尾) 例: only 2 left
     * @param {Boolean} showReducePrice 降价
     * @param {Boolean} showSaleAttr 销售属性
     * @param {Boolean} showUserAct 用户行为数据标签
     * @param {Boolean} showPriceReductionLabel 降价金额标签
     * @param {Boolean} showFeedback 负反馈
     * @param {Boolean} showAddToBoardBtn 加组按钮，注意：上面的showAddToBoard配置是用在收藏时，向上浮起的加组按钮（收藏场景接入）
     * @param {Boolean} showFindSimilarBtn 找相似按钮
     * @param {Boolean} showDeleteBtn 删除按钮
     * @param {Boolean} showAddToBoardBtnOnSoldOut 加组按钮(售罄时显示)
     * @param {Boolean} showFindSimilarBtnOnSoldOut 找相似按钮(售罄时显示)
     * @param {Boolean} showDeleteBtnOnSoldOut 删除按钮(售罄时显示)
     * @param {Boolean} showSelectOnLongClick 长按进入选择模式（参考收藏效果）
     * @param {Boolean} showSheinClubDiscountValue 付费会员折扣显示在价格下方 ***废弃***
     * @param {Boolean} showSheinClubDiscountValueAfterPrice 付费会员折扣显示在价格右侧 ***废弃***
     * @param {Boolean} showSheinClubPrice 付费会员价格
     * @param {Boolean} showCamelPrice 显示驼峰样式价格
     * @param {Boolean} showSheinClubPriceOnSale 付费会员价，占用原来的销售价
     * @param {Boolean} showPriceCut 减价（price cut）
     * 以下三个跟新型闪购价格相关的配置，根据不同场景，一般来说配置其中一项即可，都配置会相互冲突
     * @param {Boolean} showNewFlashPrice 新型闪购价
     * @param {Boolean} showNewFlashPriceOnSale 新型闪购价，占用原来的销售价
     * @param {Boolean} showOnlyNewFlashPrice 只展示新型闪购价，隐藏原来的销售价和零售价
     *
     * 新型闪购的3种标签
     * @param {Boolean} showNewFlashLabel 新型闪购底部标签（New user price, Exclusive）- 三图推荐使用
     * @param {Boolean} showNewFlashLabelBelowPrice 价格下面的新型闪购标签
     * @param {Boolean} showNewFlashLabelUpPrice 价格上方的新型闪购标签
     * 
     * @param {Boolean} showSearchFilters 分布式筛选(卖点 + 核心属性)
     * @param {Boolean} showUnknownPrice 展示未知价格
     * @param {Boolean} showDiscountLabelAfterPrice 价格后面展示折扣标签
     * @param {Boolean} showPromoLabel 大促标签
     * @param {Boolean} showBestDealLabel 低价爆品
     * @param {Boolean} showUserBehaviorLabel 用户行为标签
     * @param {Boolean} showUserPreferencesLabel 用户偏好标签
     * @param {Boolean} showUserCommentKeywordsLabel 用户评论关键词标签
     * @param {Boolean} showBeltLabel 腰带
     * @param {Boolean} showRankingList 榜单
     * @param {Boolean} autoCropImage 默认商品项为 3:4 补图逻辑，支持方图使用 autoCropImage
     * @param {Boolean} showPriceBelowGoodsName 将价格显示在名称下面
     * @param {Boolean} showStarComment 星级评论
     * @param {Boolean} showStarRating 星级评分
     * @param {Boolean} showEstimatedPrice 到手价（满足门槛和未满足门槛）
     * @param {Boolean} showEstimatedPriceReachThreshold 到手价（仅满足门槛）
     *
     * @param {Boolean} showDecisionAttr 决策属性
     * @param {Boolean} hideDiscountLabel 超出一行展示的时候隐藏超出的标签（原始需求：隐藏折扣标签）
     * 
     * @param {Boolean} standardPrice wiki：pageId=1347754607  旧商卡支持合规价
     *
     *
     * 一些可自定义的样式
     * @param {Object} style
     *    @param {String} discountLabel 左上角促销折扣角标样式，默认normal。其他选项 flashSale
     *    @param {Boolean} pricesNewline 售价和划线价各占一行
     *    @param {String} salePriceFontSize 售卖价字号, 例子：12px
     *    @param {String} salePriceColor 售卖价颜色, 例子：#fff
     *    @param {String} salePriceFontWeight 售卖价字粗, 例子：bold
     *    @param {String} lineThroughPriceFontSize 划线价字号, 例子：12px
     *    @param {Number} lineThroughPriceScale 划线价缩放, 例子：0.75
     *    @param {Boolean} showNewDiscountLabel ABT，显示价格旁边新的折扣标签样式
     *    @param {Boolean} showGreySellingPoint ABT，卖点标签abt样式
     *    @param {Boolean} showPriceholder 每日上新组件，是否展示PricePlaceholder
     *    @param {String} cropImageContainerBackground 裁剪图片容器背景色 默认：#FAFAFA
     *    @param {String} productItemMainColor 商品项主模块背景色 默认：#f2f2f2
     * */
    config: {
      type: Object,
      default: () => ( {} )
    },
    // 当前项的数据
    item: {
      type: Object,
      default: () => ( {} )
    },
    // 当前项的索引
    index: {
      type: Number,
      default: 0
    },
    // 详情url
    goodsDetailUrl: {
      type: String,
      default: ''
    },
    // 强制显示闪购促销角标颜色，因为未开始的期次没有促销活动，只能手动强制显示
    forceShowFlashSale: {
      type: Boolean,
      default: false
    },
    scrollTimers: {
      type: Number,
      default: 0
    },
    // 多语言
    language: {
      type: Object,
      default: () => ( {} )
    },
    // 延迟上报点击埋点
    delayClickAnalysis: {
      type: Boolean,
      default: false
    },
    // 底部信息样式自定义
    bottomInfoStyle: {
      type: Object,
      default: () => ( {} )
    },
    // 一些常量数据（比如：IS_RW，GB_cssRight等）
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    // 当前展示蒙层操作面板的角标
    curOperateIndex: {
      type: Number,
      default: -1
    },
    /**
     * 2 一行二图
     * 2.3 一行2.3图
     * 2.9 一行2.9图
     * 3 一行三图
     * 3l 一行三图(宽版)
     * 3.3 一行3.3图 （参考：首页闪购）
     * 3.3l 一行3.3图(宽版)
     * auto 宽度自适应
     *  */
    column: {
      type: [Number, String],
      default: 2
    },
    // 进入选择模式
    showSelect: {
      type: Boolean,
      default: false
    },
    // 展示找相似弹窗，需禁用操作按钮
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    // 展示找相似弹窗，需禁用操作按钮
    cropRate: {
      type: String,
      default: '',
    },
    // 选中状态
    selectStatus: {
      type: Boolean,
      default: false
    },
    // 禁止选择
    selectDisable: {
      type: Boolean,
      default: false
    },
    // 底部标签只展示一行
    showOneLineLabel: {
      type: Boolean,
      default: false
    },
    // 调用的模块（慎用！尽量不要通过此字段来区分不同调用方，然后在商品项里面走不同分支逻辑！！不然后续维护比较重！！）
    moduleFrom: {
      type: String,
      default: ''
    },
    // 某些元素 使用小的上下间距
    elSmallGap: {
      type: Boolean,
      default: false
    },
    // 底部元素左边距，例子：1rem
    bottomInfoLeftGap: {
      type: String,
      default: ''
    },
    // 底部元素右边距，例子：1rem
    bottomInfoRightGap: {
      type: String,
      default: ''
    },
    // LCP 方案
    lcp: Boolean,
    showPriceholder: {
      type: Boolean,
      default: false
    },
    compSrc: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      longClickTimer: null, // 长按计时器
      status: {
        showFeedback: false,  // 显示负反馈弹窗
        showSelf: true,
        showOperationPanel: false // 操作面板展示
      },
      showAddToBoard: false, // add to board按钮展示隐藏
      relateColorInfo: null, // 色块商品项数据
      preViewGoodsInfo: null, // 色块请求未回的占位
      selectedColorId: null,
      postGetInfoLoading: false,
      cornerMarkFocus: false,
      decisionAttrVisible: false,
      createComp: true, // 用于重新初始化组件
      imgRatio: '', // mainImg 图片宽高比
    }
  },
  computed: {
    curData() {
      return this.relateColorInfo && Object.prototype.hasOwnProperty.call(this.relateColorInfo.pretreatInfo || {}, 'getInfoLoading')
        ? this.relateColorInfo.pretreatInfo.getInfoLoading && this.preViewGoodsInfo
          ? this.preViewGoodsInfo
          : this.relateColorInfo
        : this.item
    },
    slicedDecisionAttr () {
      return (this.curData.decisionAttrList || []).slice(0, 3)
    },
    decisionAttrAna () {
      return this.slicedDecisionAttr.map(item => `show_key_Attribute_${item.attrId}_${item['name-en']}`).join('|')
    },
    getInfoLoading() {
      return this.relateColorInfo
        ? Object.prototype.hasOwnProperty.call(this.relateColorInfo.pretreatInfo || {}, 'getInfoLoading')
          ? this.relateColorInfo.pretreatInfo.getInfoLoading
          : true
        : false
    },
    imgDesignWidth() {
      return columnDesignWidthMap[`${this.column}`] || 0
    },
    getMainImg() {
      return this.needShowSpuImg ? this.spuImg : this.goodsImg
    }
  },
  watch: {
    curData (val, oldVal) {
      if (Number(val.goods_id) !== Number(oldVal.goods_id)) {
        this.createComp = false
        this.setElAttr()
        this.$nextTick(() => {
          this.createComp = true
        })
      }
    },
    getInfoLoading: {
      handler(val, oldVal) {
        let self = this
        let turn = function(val) {
          self.$nextTick(() => {
            self.postGetInfoLoading = val
          })
        }
        if (!val && oldVal) {
          var img = new Image()
          img.onload = function () { turn(val) }
          img.onerror = function () { turn(val) }
          self.$nextTick(() => {
            img.src = self.transformImg({ img: self.goodsImg })
          })
        } else {
          turn(val)
        }
      },
      immediate: true
    }
  },
  created() {
    const listRouteNames = ['product-list-v2', 'picked-info-list', 'feedback-rec-list']
    this.inProductList = listRouteNames.includes(this.$route?.name)

    // 提取出色块图
    if (this.index < 20 || this.item.lazyClose) {
      // ssr 直出的图片(瀑布流是 6 个, 齐平式是 20 个), 由于不会触发 load 事件, 所以直接设置色块图
      // 预加载主图的商品项, 不做色块延迟加载处理
      return
    }
    // 这里开始是第二页, 也就是客户端渲染了
    this.colors = (this.item.relatedColor || []).map(color => color.goods_color_image);
    (this.item.relatedColor || []).forEach(color => {
      color.goods_color_image = ''
    })
  },
  mounted() {
    this.$emit('mounted', this)
    // 不可在挂在时加载图片比例信息，避免商品项图片懒加载功能失效
    // this.getMainImgRatio()

    this.client = true
    if (this.index < 20 || this.item.lazyClose) {
      // 首页商品项, 不做色块延迟加载处理
      return
    }
    const mainImageEl = this.$el.querySelector('.product-item__main-img')
    let imageLoaded = false
    if (mainImageEl) {
      mainImageEl.addEventListener('load', () => {
        if (!mainImageEl.src.includes('/pwa_dist') && Array.isArray(this.item.relatedColor) && this.item.relatedColor.length > 0) {
          // 说明是商品主图, 而非占位图
          // then load the color images
          if (imageLoaded) {
            return
          }
          imageLoaded = true
          for (let i = 0; i < this.item.relatedColor.length; i++) {
            const color = this.item.relatedColor[i]
            // 在 信息流落地页和实时反馈落地页 的场景下, created 的时候, this.item.relatedColor 是没有值的
            // 值是后面原子服务返回的, 这就导致 this.colors 仍然是空数组
            // 所以这里需要兼容一下, 相当于信息流场景, 色块并不是延迟加载的
            color.goods_color_image = this.colors[i] || color.goods_color_image
          }
        }
      })
    }
  },
  methods: {
    transformImg,
    getItemWidth() {
      const fontSize = document.documentElement.style.fontSize.replace('px', '')
      const columnMapWidth = {
        '2': 4.52,
        '2.3': 4,
        '2.9': 3.156,
        '3s': 2.6933,
        '3': 2.9733,
        '3l': 3.08,
        '3.3': 2.8,
        '3.3l': 2.96,
        '3.5': 2.42
      }
      
      const leftGap = this.bottomInfoLeftGap.replace('rem', '')
      const rightGap = this.bottomInfoRightGap.replace('rem', '')
      return (columnMapWidth[this.column] - leftGap - rightGap) * fontSize
    },
    setElAttr() {
      const pretreatInfo = this.curData?.pretreatInfo || {}
      const mixPromotionInfo = pretreatInfo.mixPromotionInfo || []
      const promoLabelSrc = this.config.showPromoLabel && this.curData?.promoLabel?.src
      const isSoldOut = this.curData.is_on_sale == 0 || this.curData.stock == 0 || this.curData.sale_percent == 1
      // 到手价的一些信息
      const { estimatedPrice, satisfied, showABPrice } = this.curData.estimatedPriceInfo || {}
      const estimatedPriceUsdAmount = estimatedPrice?.usdAmount // 到手美元价
      const satisfiedVal = showABPrice ? 3 : ([2, 1][satisfied] || '')
      let haveTopLeftBadge = this.curData?.mobileVerticalView?.locateLabels?.UPPER_LEFT_LABEL ?? false //左上角是否有角标(3p)
      const dataShowLabelHot = ((this.config.showTitle && this.curData?.goods_name && !this.frontTitle?.code && this.showHotTag === 2) || (!this.imgUpperLeft?.code && !haveTopLeftBadge && this.showHotTag === 1)) ? 'show_label_hot' : '' // 3p > new > hot, 只有真正展示hot标才上报埋点
      // 31品类限定商家限时直降 wiki.pageId=1198803653
      let showSellerLimitedLabel = ''
      if (mixPromotionInfo.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)) {
        showSellerLimitedLabel = 'show_seller_limited_label'
      }

      this.setAttrForAnalysis((el, isMainCtn) => {
        if (!this.config.useOwnClickExposeAnalysis) {
          el.setAttribute('da-event-click', !this.delayClickAnalysis ? (this.config.itemDAEventClickId || '2-3-1') : '')
          isMainCtn && el.setAttribute('da-event-expose', this.config.itemDAEventExposeId || '2-3-2')
        }
        el.setAttribute('data-sku', this.curData?.goods_sn || '')
        el.setAttribute('data-spu', this.curData?.productRelationID || '')
        el.setAttribute('data-id', this.curData?.goods_id || '')
        el.setAttribute('data-name', this.curData?.goods_name || '')
        el.setAttribute('data-rec_mark', this.curData?.ext?.rec_mark || '')
        el.setAttribute('data-extra_mark', this.curData?.ext?.extra_mark || '')
        el.setAttribute('data-other_ext_mark', this.curData?.ext?.other_ext_mark || '')
        el.setAttribute('data-other_d_ext_mark', this.curData?.dynamic_ext?.other_d_ext_mark || '')
        el.setAttribute('data-cat_id', this.curData?.cat_id || '')
        el.setAttribute('data-price', this.curData?.salePrice?.amount || '')
        el.setAttribute('data-us-price', this.curData?.salePrice?.usdAmount || '')
        el.setAttribute('data-us-origin-price', this.curData?.retailPrice?.usdAmount || '')
        el.setAttribute('data-index', this.index)
        el.setAttribute('data-type', this.curData?.dataSource || '') // config.moduleFrom == 'recommend'
        el.setAttribute('data-similar', isSoldOut && this.config.showSimilarBtn || '')
        el.setAttribute('data-reduce-price', !isSoldOut && pretreatInfo.reducePrice?.usdAmount || '')
        el.setAttribute('data-soldout', Number(isSoldOut)) // data-soldout 目前只有在最近页面有用到
        el.setAttribute('data-sold-out', Number(isSoldOut)) // data-sold-out 需要用到其他页面上，因为上报的标识不一样，需要另外设置一个属性
        el.setAttribute('data-show-exclusive-price', this.curData.exclusivePromotionPrice || '')
        el.setAttribute('data-lable', pretreatInfo.label || '')
        el.setAttribute('data-series-brand', !promoLabelSrc && pretreatInfo.seriesOrBrandAnalysis || '')
        el.setAttribute('data-brand', this.curData?.brand?.name || '')
        el.setAttribute('data-brand-code', this.curData?.brand?.brand_code || '')
        el.setAttribute('data-video', this.curData?.video_url ? 'video_icon' : '')
        el.setAttribute('data-spu-img', this.spuImg ? (this.needShowSpuImg ? 'spupic_1' : 'spupic_0') : '') // 有spu图且展示则上报spupic_1，有spu图但不展示则上报spupic_0，无spu图不上报
        el.setAttribute('data-mall_tag_code', `${pretreatInfo.mallTagsInfo?.mall_tags || ''}_${this.curData?.mall_code || '-'}`)
        el.setAttribute('data-store_code', this.curData?.store_code ?? '')
        el.setAttribute('data-sale-attr', pretreatInfo.saleAttr?.analysis || '')
        el.setAttribute('data-show-promot-info', this.labelsShow.promotionLabel || '')
        el.setAttribute('data-price-cut', pretreatInfo.priceCut || '')
        el.setAttribute('data-promotion-id', mixPromotionInfo.map(item => item?.id).join('`') || '')
        el.setAttribute('data-type-id', mixPromotionInfo.map(item => item?.typeId).join('`') || '')
        el.setAttribute('data-best-deal', pretreatInfo.showBestDeal ? 1 : '')
        el.setAttribute('data-follow-label', this.followLabelVisible ? 1 : '')
        el.setAttribute('data-promo-label', promoLabelSrc ? 1 : '')
        el.setAttribute('data-user-behavior-label', pretreatInfo?.mobileVerticalView?.userBehaviorLabel?.ana || '')
        el.setAttribute('data-user-comment-keywords-label', pretreatInfo?.mobileVerticalView?.userCommentKeywordsLabel?.ana || '')
        el.setAttribute('data-ranking-list', this.rankingList?.ana || '')
        el.setAttribute('data-belt-label', pretreatInfo.mobileVerticalView?.beltLabel?.ana || '')
        el.setAttribute('data-decision-attr', this.decisionAttrVisible ? this.decisionAttrAna : '')
        el.setAttribute('data-show-label-hot', dataShowLabelHot)
        el.setAttribute('data-locate-labels', this.locateLabelsAna || '')
        el.setAttribute('data-show-seller-limited-label', showSellerLimitedLabel)
        if(this.item.isHypernymGoods) {
          el.setAttribute('data-hypernym-goods', 1)
        }
        
        const badges = []
        const dataBadges = el.getAttribute('data-badges')
        const goodsName = this.config.showCateName && this.curData?.cat_name || this.curData?.goods_name
        const upLeftBadges = goodsName && this.config.showTitle && this.frontTitle?.code || this.imgUpperLeft?.code
        if (dataBadges) badges.push(dataBadges)
        if (upLeftBadges) badges.push(upLeftBadges)
        el.setAttribute('data-badges', badges)

        if (satisfiedVal && estimatedPriceUsdAmount) {
          el.setAttribute('data-estimated-price', `${satisfiedVal}\`${estimatedPriceUsdAmount}`)
        }
      })
    },
    /**
     * 自定义埋点字段
     * @param {Array} attrArr [{ attr: xxx, value: xxx }]
     */
    customizeElAttr(attrArr = []) {
      attrArr.forEach(({ attr, value }) => {
        this.setAttrForAnalysis((el) => {
          el.setAttribute(attr, value)
        })
      })
    },
    async handleColorClick (item, selectedColorId) {
      this.$emit('clickColor', item)
      this.handleShowAddBoard(false)
      this.selectedColorId = selectedColorId
      await this.setRelateColorInfo(this.selectedColorId)
      await this.$nextTick()
      exposeProductItem({ ...this.config, target: [this.$refs.productItemWrapper] })
    },
    async setRelateColorInfo (selectedColorId) {
      const { cat_name } = this.curData // 切换色块，需要保留cat_name
      let goodsItem = {}
      // 切换的多颜色跟主商品是同个skc，则直接用主商品数据
      if (selectedColorId === Number(this.item.goods_id)) {
        goodsItem = this.item
      } else {
        goodsItem = this.relatedColor.find(item => selectedColorId === Number(item.goods_id))
      }

      if (!goodsItem.cat_name && cat_name) {
        goodsItem.cat_name = cat_name
      }

      this.preViewGoodsInfo = this.curData

      await this.$nextTick()

      this.relateColorInfo = goodsItem
      // this.curData.spu_image = '' // 切色块，不显示spu图
    },
    // 显示隐藏add to board
    handleShowAddBoard (showAddToBoard) {
      this.showAddToBoard = showAddToBoard
    },
    // 收藏/取消收藏
    handleToggleWish (payload) {
      this.$emit('toggleWish', payload)
    },

    async navigateToProductDetails ()  {
      // 进入商品详情页前, 进行打点
      markPoint('toNextPageClick', 'public')
      // 跳转商详
      const url = await this.handleGoodsUrl({ ...this.curData })

      // 存图片给详情使用
      const lazyLoadSrc = this.getLazyloadSrc()
      addProductDetailBfCache(this.curData, lazyLoadSrc, this.config.showSpuImg)
      if (this.$route) {
        this.$route.meta.isForward = true
        this.config.spaRouterJumpType == 'replace' ? this.$router.replace(url) : this.$router.push(url)
      } else {
        // fix: 跳转太快，埋点发送有可能被取消，下一个event loop再跳转
        setTimeout(() => {
          this.config.spaRouterJumpType == 'replace' ? window.location.replace(url) : window.location.href = url
        }, 100)
      }
    },

    async clickItem (e, { from } = {}) {
      // 点击商品项，不跳商详的情况
      if (this.disableJumpDetail(e)) return
      // 进入商品详情页前, 进行打点
      markPoint('toNextPageClick', 'public')
      // 跳转商详
      const url = await this.handleGoodsUrl({ ...this.curData })
      const imgRatio = await this.getMainImgRatio()

      // 存图片给详情使用
      const lazyLoadSrc = this.getLazyloadSrc()
      addProductDetailBfCache(this.curData, lazyLoadSrc, this.config.showSpuImg)

      // 点击商品项，透传事件
      this.$emit('clickItem', {
        ...this.curData,
        imgRatio,
        goodsUrl: url,
        index: this.index,
        target: this.$refs.productItemWrapper,
        scrollPos: $(window).scrollTop() // 记录当前点击商品项的scrollTop
      }, this.$el)

      if (!this.config.disableMainimgJump) {
        if (this.$route) {
          this.$route.meta.isForward = true
          this.config.spaRouterJumpType == 'replace' ? this.$router.replace(url) : this.$router.push(url)
        } else {
          // fix: 跳转太快，埋点发送有可能被取消，下一个event loop再跳转
          setTimeout(() => {
            this.config.spaRouterJumpType == 'replace' ? window.location.replace(url) : window.location.href = url
          }, 100)
        }
      }

      // trigger点击埋点
      if (this.delayClickAnalysis || from == 'soldOut') {
        if (this.delayClickAnalysis) {
          await this.$nextTick()
        }
        
        clickProductItem({ 
          itemDAEventClickId: this.config.itemDAEventClickId || '2-3-1', 
          target: this.$refs.productItemWrapper 
        })
      }
    },
    // 钩子商品点击加购按钮，根据 abt 跳转商详 / 加购
    handleHookAddBagEvent() {
      if(this.config.addBagToDetail) {
        this.clickItem()
      } else {
        this.handleOpenQuickAdd()
      }
      this.$emit('hookAddBagEvent')
    },
    getLazyloadSrc () {
      const { LAZY_IMG_SOLID_COLOR = '' } = this.constantData || {}
      let lazyLoadSrc = ''
      try {
        const imgElement = this.$el.querySelector('.product-item__main-img')
        lazyLoadSrc = imgElement.getAttribute('src') || ''
        if (lazyLoadSrc === LAZY_IMG_SOLID_COLOR) {
          return ''
        }
      } catch (err) {
        return ''
      }

      return lazyLoadSrc
    },
    disableJumpDetail (e) {
      // 编辑状态选中商品
      if (this.showSelect) {
        e.stopPropagation() // 阻止冒泡触发埋点
        this.handleSelect()
        return true
      }
    },
    async handleGoodsUrl ({ goods_url_name = 'product', goods_id, cat_id, mall_code }) {
      const transformSrc = this.transformImg({ img: this.getMainImg })

      const productDetailUrl = await getProductDetailUrl({
        imgSrc: transformSrc,
        goods_url_name,
        goods_id,
        cat_id,
        mall_code,
        urlQuery: this.config.urlQuery,
        langPath: this.constantData.langPath,
        goodsDetailUrl: this.goodsDetailUrl
      })

      return productDetailUrl
    },
    handleSelect () {
      if (this.selectDisable) return

      this.$emit('select', { item: this.curData, selected: !this.selectStatus })
    },
    touchstart () {
      this.longClickTimer = setTimeout(() => {
        this.longClick()
      }, this.config.longClickTime || 1000)
    },
    touchmove () {
      clearTimeout(this.longClickTimer)
      this.longClickTimer = null
    },
    touchend (e) {
      if (this.emitLongClick) {
        e.preventDefault()
        this.emitLongClick = false
      }
      clearTimeout(this.longClickTimer)
      return false
    },
    longClick () {
      this.emitLongClick = true
      this.emitEvent('longClick')
    },
    handleClickMoreBtn () {
      this.emitEvent('clickMoreBtn')
    },
    emitEvent (event) {
      if (this.isSoldOut && !this.showSimilarPopup) return

      let isShow = false
      if (this.hasFeedback) {
        const curStatus = !this.status.showFeedback
        isShow = this.status.showFeedback = curStatus

        // hasFeedback 负反馈用到了 imgRatio 在展示的时候才加载图片比例
        if (!this.imgRatio) {
          this.getMainImgRatio()
        }
      } else if (this.showOperatinPanel) {
        const curStatus = !this.status.showOperationPanel
        if ((event == 'longClick' && !this.config.showSelectOnLongClick) || event == 'clickMoreBtn') {
          this.status.showOperationPanel = curStatus
        }
        isShow = curStatus
      }

      this.$emit(event, { index: this.index, isShow, item: this.curData })
    },
    handleDeleteOperation() {
      this.status.showOperationPanel = false
    },
    hideSelf () {
      this.status.showSelf = false
    },
    handleExposeSellingPoint (text) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-selling-point', text)
      })
    },
    handleShowCccTspBadge (isShow) {
      const badges = []
      if (this.imgBottomLeft?.code) badges.push(this.imgBottomLeft.code)
      if (this.goodsBottom?.code && isShow) badges.push(this.goodsBottom.code)

      this.setAttrForAnalysis((el) => {
        const badges = []
        const dataBadges = el.getAttribute('data-badges')
        const showBadges = badges.join('|')
        if (dataBadges) badges.push(dataBadges)
        if (showBadges) badges.push(showBadges)
        el.setAttribute('data-badges', badges.join('|'))
      })
    },
    handleShowUserAct (text) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-user-act', text)
      })
    },
    handleShowQuickShip () {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-quick-ship', 'quickship_1')
      })
    },
    handleShowSearchFilter (payload) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-search-filter', payload)
      })
    },
    setAttrForAnalysis(fn) {
      const setElAttr = (el, isMainCtn = true) => {
        typeof fn === 'function' && fn(el, isMainCtn)
      }
      this.$refs.productItemWrapper && setElAttr(this.$refs.productItemWrapper)
      const nameCtn = this.$el.querySelector('.product-item__name-wrapper')
      nameCtn && setElAttr(nameCtn, false)
    },
    handleOpenQuickAdd () {
      if (!this.imgRatio) {
        this.getMainImgRatio()
      }

      this.$emit('openQuickAdd', {
        item: this.curData,
        index: this.index,
        target: this.$refs.productItemWrapper,
        imgRatio: this.imgRatio
      }, this.$el)
      
      // 埋点
      !this.config.useOwnClickAddBagAnalysis && clickAddBagAna({ target: this.$refs.productItemWrapper })
    },
    async getMainImgRatio() {
      const transformSrc = this.transformImg({ img: this.getMainImg })

      return this.imgRatio = await getImageRatio(transformSrc)
    }
  },
  render(h) {
    // 商品宽度
    let itemWidth
    if(this.client) {
      itemWidth = this.getItemWidth()
    } 
    // constantData
    const { IS_RW } = this.constantData || {}
    // config
    const { showDecisionAttr, showSpuImg, showFeedback, showPlusSize, showSeriesBrand, showAddToBoardBtn, showFindSimilarBtn, showDeleteBtn, showPromoLabel, hideNormalPrices, showOnlyNewFlashPrice, showMultiColor, colorSetPresentedType, showSearchFilters, showSellingPoint, showNewFlashLabel, showLocalSeller, showQuickShip, showPriceCut, showPromotion, showBadge, hideBottomCccTspBadge, showLeftStock, showStarRating, showStarComment, showAddToBoard, hideSoldOut, showNewFlashPrice, showAddBagAfterGoodsPrice, showPriceBelowGoodsName, showMyFlashHookPrice, showTitle, showCateName, showCamelPrice, hideRetailPrice, searchFiltersWithoutSellingPoint, showNewFlashLabelUpPrice, showHotLocals, isListstarB, autoCropImage, showRankingList, productItemMainColor, cropImageContainerBackground, showEstimatedPrice, showEstimatedPriceReachThreshold } = this.config || {}

    // 业务数据 预处理对象
    const pretreatInfo = this.pretreatInfo = this.curData.pretreatInfo || {}

    // 主图
    this.goodsImg = this.curData?.goods_img || ''
    const spuImg = this.spuImg = ((this.curData?.spu_image || [])[0]?.medium_image) || ''
    const needShowSpuImg = this.needShowSpuImg = !!(showSpuImg && spuImg && spuImg !== 'filteredSpuImg')
    const mainImg = needShowSpuImg ? spuImg : this.goodsImg
    // 商品名称
    const goodsName = showCateName && this.curData?.cat_name || this.curData?.goods_name || ''
    const cccFeedback = this.curData?.cccFeedback || {}
    const hasFeedback = this.hasFeedback = cccFeedback.hasOwnProperty('negFeedbackInfo') && showFeedback
    // 标题前ccc-tsp角标
    const frontTitle = this.frontTitle = pretreatInfo?.frontTitle || null
    // hot标, 1左上， 2名字前， 0不展示
    const showHotTag = this.showHotTag = this.curData?.tspLabels?.hot_color == 1 ? ( showHotLocals ? 2 : 1) : 0 // 1左上，2名字前，0不展示
    // 图片左上角ccc-tsp角标
    this.imgUpperLeft = pretreatInfo?.imgUpperLeft || null
    // 图片左下角ccc-tsp角标
    this.imgBottomLeft = pretreatInfo?.imgBottomLeft || null
    // 商品下方ccc-tsp角标
    const goodsBottom = this.goodsBottom = pretreatInfo?.goodsBottom || null
    // 收藏暂时不展示品牌系列。没有系列，有大码时，展示大码，否则不展示大码
    const plusSize = showPlusSize && pretreatInfo.plusSize || ''
    // romwe系列品牌
    const seriesOrBrandLogoBottom = showSeriesBrand && pretreatInfo?.seriesOrBrandLogoBottom
    // romwe独创款
    const romweoriginals = showSeriesBrand && pretreatInfo?.romweoriginals
    // 图片底部的badge
    const hasBottomBadge = !!(plusSize || seriesOrBrandLogoBottom || romweoriginals)
    // 促销
    const promotionInfo = pretreatInfo.mixPromotionInfo?.[0] || null
    // 到手价的一些信息
    const { 
      estimatedDiscount, 
      estimatedDiscountPrice, 
      reachTreshold, 
      estimatedPriceWithSymbolVal 
    } = (() => {
      const { estimatedDiscount, estimatedDiscountPrice, satisfied, estimatedPrice } = this.curData.estimatedPriceInfo || {}
      const estimatedPriceWithSymbol = estimatedPrice?.amountWithSymbol
      const reachTreshold = satisfied === 1 // 满足门槛
      const estimatedPriceWithSymbolVal = (showEstimatedPrice || showEstimatedPriceReachThreshold) && reachTreshold && estimatedPriceWithSymbol || ''

      return { estimatedDiscount, estimatedDiscountPrice, reachTreshold, estimatedPriceWithSymbolVal }
    })()
    // 是新型闪购（新人，专属）
    const newFlashPromotion = pretreatInfo?.newFlashPromotion || null
    const flashTypeText = pretreatInfo?.newFlashPromotion?.flashTypeText || ''
    const newFlashLabelUpPriceText = showNewFlashLabelUpPrice ? flashTypeText : ''
    // 预售（coming soon）
    const comingSoon = +this.curData.new_product_unsale === 1
    // 售罄状态
    const isSoldOut = this.isSoldOut = this.curData.is_on_sale == 0 || this.curData.stock == 0 || this.curData.sale_percent == 1
    const showBtns = showAddToBoardBtn || showFindSimilarBtn || showDeleteBtn
    const showOperatinPanel = this.showOperatinPanel = showBtns && !this.showSelect && (!isSoldOut || hideSoldOut)
    // 列数的配置
    const columnNum = isString(this.column) ? Number(this.column.slice(0, -1)) : this.column
    const multiColumn = columnNum > 2
    const isSmallMultiColumn = this.column === '2.5s'
    
     
    const {
      beltLabel,
      showBestDeal,
      followLabelVisible,
      isPromoPrice,
    } = controlBeltPrice(this.config, {
      pretreatInfo,
      isSoldOut,
    })
    this.followLabelVisible = followLabelVisible

    // 大促角标
    const promoLabelSrc = showPromoLabel && this.curData?.promoLabel?.src
    // 更多按钮
    const showMoreBtn = hasFeedback || ((!isSoldOut) && showOperatinPanel) || this.showSimilarPopup
    // 价格
    const retailPrice = this.curData?.retailPrice || {}
    const salePrice = this.curData?.salePrice || {}
    const customPriceLine = this.$scopedSlots.customPriceLine?.({
      isSoldOut,
      salePrice,
      retailPrice,
      pretreatInfo,
      handleOpenQuickAdd: this.handleOpenQuickAdd,
    }) ?? null
    const showNormalPrice = !hideNormalPrices && (!showOnlyNewFlashPrice || !newFlashPromotion) && !customPriceLine
    // s3 vip价格
    const exclusivePromotionPrice = this.curData.exclusivePromotionPrice || null
    // 付费会员
    const { 
      price: sheinClubPrice, 
      discountValue: sheinClubDiscountValue,
      discountPercentString, 
      discount_amount,
    } = this.curData.sheinClubPromotionInfo || {}
    // const showSmallSheinClubLabel = !!(sheinClubDiscountValue && showSheinClubDiscountValueAfterPrice)
    const showSmallSheinClubLabel = false
    const showSheinClubPrice = this.config.showSheinClubPriceOnSale && sheinClubPrice?.amountWithSymbol
    // 可省金额
    let discountPrice = ''
    // 折扣标签信息
    const setDiscountInfo = (unitDiscountString) => {
      if (!pretreatInfo.discountInfo) pretreatInfo.discountInfo = {}
      Object.assign(pretreatInfo.discountInfo, { unitDiscountString, show: true })
    }
    if (estimatedPriceWithSymbolVal && estimatedDiscount) { // 到手价折扣率
      setDiscountInfo(estimatedDiscount)
      discountPrice = estimatedDiscountPrice?.amountWithSymbol || ''
    } else if (showSheinClubPrice && discountPercentString) { // 付费会员折扣率
      setDiscountInfo(discountPercentString)
      discountPrice = discount_amount?.amountWithSymbol || ''
    }
    const discountInfo = pretreatInfo.discountInfo || {}

    // 榜单
    const rankingList = this.rankingList = pretreatInfo?.mobileVerticalView?.rankingList

    // 行为标签
    const userBehaviorLabelInfo = pretreatInfo?.mobileVerticalView?.userBehaviorLabel
    const userBehaviorLabelLang = userBehaviorLabelInfo?.labelLang

    // 评论关键词标签
    const userCommentKeywordsLabelInfo = pretreatInfo?.mobileVerticalView?.userCommentKeywordsLabel
    const userCommentKeywordsLabelLang = userCommentKeywordsLabelInfo?.labelLang || ''

    // 闪购
    const isFlashSale = !!pretreatInfo?.flashsale || this.forceShowFlashSale

    // 色块
    const relatedColor = this.relatedColor = this.item.relatedColor || []
    const showColorSwiper = showMultiColor && !!relatedColor.length && !colorSetPresentedType
    // 本地mall
    const { localMall, language_mall_name } = pretreatInfo?.mallTagsInfo || {}
    const localMallName = localMall && language_mall_name
    // 卖点
    const sellingPoints = pretreatInfo?.sellingPoints
    // 卖点在标签中展示
    const showSellingPointsOnLabel = (!showSearchFilters || searchFiltersWithoutSellingPoint) && showSellingPoint
    // 底部标签
    const labelsShow = this.labelsShow = {
      flashTypeText: showNewFlashLabel && flashTypeText,
      localMallName: showLocalSeller && localMallName,
      quickShip: showQuickShip && (this.curData.quickship || this.curData.quick_ship),
      priceCut: showPriceCut && pretreatInfo?.priceCut,
      promotionLabel: showPromotion && promotionInfo?.lable || '',
      sellingPoints: showSellingPointsOnLabel && sellingPoints,
      cccTspBadges: showBadge && !hideBottomCccTspBadge && goodsBottom?.text,
      stockLeft: IS_RW && showLeftStock && pretreatInfo?.stockLeft, // 剩余库存（仅romwe）
    }

    // 合规价三列以上商卡不显示服务标签
    const isSuggestedSpecial = pretreatInfo?.suggestedSalePriceConfigInfo?.type
    const hasNormalLabels = !(isSuggestedSpecial && multiColumn) && Object.keys(labelsShow).some(key => labelsShow[key])
    
    // 商品下方角标样式
    const goodsBottomBadgeStyle = (() => {
      const style = {}
      const fontColor = goodsBottom?.fontColor
      const backgroundColor = goodsBottom?.backgroundColor
      if(fontColor) style.color = fontColor
      if(backgroundColor) style.backgroundColor = backgroundColor

      return style
    })()
    // 展示小号的星级评分
    const { hasStarRating, hasStarComment } = getStarVisible({ showStarRating, showStarComment, isListstarB }, this.curData.starComment)
    // 展示大号的星级评分
    this.hasStarComment = hasStarComment
    this.showRankingListInStarRating = hasStarRating && !userBehaviorLabelLang && !userCommentKeywordsLabelLang
    const imgDesignWidth = this.imgDesignWidth || 0

    // ada
    const itemAdaOptions = this.cornerMarkFocus ? {} : {
      'tabindex': 0,
      'aria-label': goodsName,
      role: 'listitem'
    }

    // 底部区域自定义样式
    const gapStyle = {}
    this.bottomInfoLeftGap && (gapStyle.paddingLeft = this.bottomInfoLeftGap)
    this.bottomInfoRightGap && (gapStyle.paddingRight = this.bottomInfoRightGap)
    const bottomInfoStyle = Object.assign(gapStyle, this.bottomInfoStyle)

    const goodsNameVisible = goodsName && showTitle
    const rankingListVisible = showRankingList && rankingList && !this.showRankingListInStarRating
    // 榜单有两个位置，看showRankingListInStarRating
    const decisionAttrVisible = this.decisionAttrVisible = showDecisionAttr && this.slicedDecisionAttr.length >= 2 && !rankingList
    // 主图信息角标
    const locateLabels = this.curData.mobileVerticalView?.locateLabels || {}
    const {
      locateLabelsAna,
      ItemLocateLabels,
      ProductItemImgTopLeft,
      ProductItemImgBottomLeft,
      ProdcutItemImgTopRight,
    } = controlItemLabels(h, {
      locateLabels,
      multiColumn,
      imgDesignWidth,
      isFlashSale,
      pretreatInfo,
      constantData: this.constantData,
      config: this.config,
      language: this.language,
      curData: this.curData,
      hasBottomBadge,
      changeMarkFocus: (flag) => (this.cornerMarkFocus = flag),
      promoLabelSrc,
      selectStatus: this.selectStatus,
      showSelect: this.showSelect,
      showSmallSheinClubLabel,
      showHotTag,
    })
    this.locateLabelsAna = locateLabelsAna

    return (
      <div
        v-show={this.status.showSelf}
        ref="productItemWrapper"
        class={[
          'product-item-ctn',
          {
            'product-item-ctn_disable': this.selectDisable,
            'product-item-ctn_col-2': this.column == 2,
            'product-item-ctn_col-23': this.column == 2.3,
            'product-item-ctn_col-29': this.column == 2.9,
            'product-item-ctn_col-3': this.column == 3,
            'product-item-ctn_col-3l': this.column == '3l',
            'product-item-ctn_col-3s': this.column == '3s',
            'product-item-ctn_col-33': this.column == 3.3,
            'product-item-ctn_col-33l': this.column == '3.3l',
            'product-item-ctn_col-35': this.column == '3.5',
            'product-item-ctn_col-12l': this.column == '1.2',
            'product-item-ctn_col-auto': this.column == 'auto',
          },
        ]}
      >
        {/* goods覆盖的上层的slot */}
        { this.$slots.goodsMain }
        {/* 主图区域 */}
        <div
          class={'product-item__main'}
          style={{
            '--productItemMainColor': productItemMainColor || '#f2f2f2'
          }}
          {...itemAdaOptions}
          vOn:touchstart={this.touchstart}
          vOn:touchmove={this.touchmove}
          vOn:touchend={this.touchend}
          vOn:contextmenu_prevent={() => {}}
          vOn:click_prevent={this.clickItem}
        >
          { this.$slots.ImgMain }
          {/* 主图 */}
          <ProductItemImg
            relate-color-info={this.relateColorInfo}
            constant-data={this.constantData}
            lcp={this.lcp}
            index={this.index}
            img-design-width={imgDesignWidth}
            main-img={mainImg}
            goods-img={this.goodsImg}
            goods-name={goodsName}
            crop-rate={this.cropRate}
            in-product-list={this.inProductList}
            auto-crop-image={autoCropImage}
            crop-image-container-background={cropImageContainerBackground}
            comp-src={this.compSrc}
          />
          {/* 蒙层操作面板 */}
          {showOperatinPanel ? (
            <ProductItemOperationPanel
              v-model={this.status.showOperationPanel}
              language={this.language}
              config={this.config}
              index={this.index}
              cur-operate-index={this.curOperateIndex}
              cur-data={this.curData}
              is-sold-out={isSoldOut}
              vOn:clickAddToBoard={(payload) => this.$emit('clickAddToBoard', payload)}
              vOn:clickDelete={(payload) => this.$emit('clickDelete', payload)}
            />
          ) : null}
          <ClientOnly>
            {ItemLocateLabels()}
            {/* 图片左上角 */}
            {this.$slots.imgTopLeft || ProductItemImgTopLeft()}
            {/* 图片右上角 */}
            {ProdcutItemImgTopRight()}
            {/* 图片左下角角标 */}
            {ProductItemImgBottomLeft()}
            {/* 图片底部横向 */}
            <ProductItemImgBottom
              pretreat-info={pretreatInfo}
              discount-amount={this.item?.discountAmount}
              config={this.config}
              plus-size={plusSize}
              has-bottom-badge={hasBottomBadge}
              series-or-brand-logo-bottom={seriesOrBrandLogoBottom}
              romwe-originals={romweoriginals}
              constant-data={this.constantData}
              language={this.language}
              coming-soon={comingSoon}
              is-sold-out={isSoldOut}
              show-best-deal={showBestDeal}
              follow-label-visible={followLabelVisible}
              belt-label={beltLabel}
              related-color={relatedColor}
              estimated-discount={estimatedDiscount}
              estimated-discount-price={estimatedDiscountPrice}
              estimated-reach-treshold={reachTreshold}
              discount-price={discountPrice}
            >
              {/* 图片底部slot(嵌套) */}
              <template slot="imgBottom">{this.$slots.imgBottom}</template>
            </ProductItemImgBottom>
            {/* 负反馈弹窗 */}
            {
              hasFeedback ? (
                <ProdcutItemFeedback
                  v-model={this.status.showFeedback}
                  language={this.language}
                  cur-data={this.curData}
                  feedback={cccFeedback}
                  cur-operate-index={this.curOperateIndex}
                  index={this.index}
                  config={this.config}
                  is-sold-out={isSoldOut}
                  scroll-timers={this.scrollTimers}
                  img-ratio={this.imgRatio}
                  vOn:hideSelf={this.hideSelf}
                />
              ) : null
            }
            {/* add to board加组弹窗 */}
            {
              showAddToBoard ? (
                <ProductItemWishlistGroup
                  show={this.showAddToBoard}
                  language={this.language}
                  cur-data={this.curData}
                  config={this.config}
                />
              ) : null
            }
            {/* 图片右下角 */}
            <div class="product-item__img-bottomright">
              {
                this.$slots.imgBottomRight || (
                  <ProductItemImgBottomRight
                    cur-data={this.curData}
                    config={this.config}
                    language={this.language}
                    index={this.index}
                    constant-data={this.constantData}
                    module-from={this.moduleFrom}
                    related-color={relatedColor}
                    need-show-spu-img={needShowSpuImg}
                    is-sold-out={isSoldOut}
                    show-select={this.showSelect}
                    vOn:showAddBoard={this.handleShowAddBoard}
                    vOn:toggleWish={this.handleToggleWish}
                    vOn:openQuickAdd={this.handleOpenQuickAdd}
                  />
                )
              }
            </div>
            {/* 售罄背景slot，默认展示Sold Out */}
          </ClientOnly>
          {
            this.$slots.soldout  || (
              !hideSoldOut && isSoldOut && !comingSoon
                ? (
                  <ProductItemSoldout
                    cur-data={this.curData}
                    language={this.language}
                    constant-data={this.constantData}
                    config={this.config}
                    is-sold-out={isSoldOut}
                    index={this.index}
                    show-select={this.showSelect}
                    show-similar-popup={this.showSimilarPopup}
                    vOn:clickAddToBoard={(payload) => this.$emit('clickAddToBoard', payload)}
                    vOn:clickDelete={(payload) => this.$emit('clickDelete', payload)}
                    vOn:select={this.handleSelect}
                    vOn:clickItem={() => this.clickItem(null, { from: 'soldOut' } )}
                  />
                )
                : null
            )
          }
          {/* 切色块 loading */}
          {
            relatedColor.length > 0 && this.postGetInfoLoading
              ? <s-item-loading show={this.postGetInfoLoading} />
              : null
          }
        </div>
        {/* 新型闪购价（新人，专属） */}
        {
          showNewFlashPrice || showOnlyNewFlashPrice && newFlashPromotion ? (
            <ProductItemNewFlashPrice
              new-flash-promotion={newFlashPromotion}
              language={this.language}
              show-retail-price={showOnlyNewFlashPrice && !hideRetailPrice}
              retail-price={retailPrice}
              show-more-btn={showMoreBtn && !showNormalPrice}
              vOn:clickMoreBtn={this.handleClickMoreBtn}
            />
          ) : null
        }
        {/* 下面商品信息区域 */}
        <div
          class={[
            'product-item__bottom-info',
            isSmallMultiColumn
              ? 'product-item__bottom-info_smaller-gap'
              : '',
            {
              'product-item__bottom-info_soldout': isSoldOut,
            }
          ]}
          style={bottomInfoStyle}
          vOn:click_prevent={this.clickItem}
        >
          {/* 价格 */}
          {
            !showPriceBelowGoodsName && !showMyFlashHookPrice ? (
              <ProductItemPrices
                is-sold-out={isSoldOut}
                sale-price={salePrice}
                retail-price={retailPrice}
                show-camel-price={showCamelPrice}
                exclusive-promotion-price={exclusivePromotionPrice}
                has-feedback={hasFeedback}
                show-operatin-panel={showOperatinPanel}
                config={this.config}
                constant-data={this.constantData}
                cur-data={this.curData}
                pretreat-info={pretreatInfo}
                multi-column={multiColumn}
                is-promo-price={isPromoPrice}
                show-normal-price={showNormalPrice}
                new-flash-promotion={newFlashPromotion}
                show-more-btn={showMoreBtn}
                shein-club-discount-value={sheinClubDiscountValue}
                show-small-shein-club-label={showSmallSheinClubLabel}
                bottom-info-right-gap={this.bottomInfoRightGap}
                flash-type-text={flashTypeText}
                new-flash-label-up-price-text={newFlashLabelUpPriceText}
                language={this.language}
                discount-info={discountInfo}
                comp-src={this.compSrc}
                vOn:openQuickAdd={this.handleOpenQuickAdd}
                vOn:clickMoreBtn={this.handleClickMoreBtn}
              >
                {/* 价格slot */}
                <template slot="price">{this.$slots.price}</template>
                {/* 热度slot */}
                <template slot="score">{this.$slots.score}</template>
              </ProductItemPrices>
            ) : null
          }
          {/* 标题 */}
          {
            goodsNameVisible ? (
              <ProductItemGoodsName
                config={this.config}
                show-hot-tag={showHotTag}
                goods-name={goodsName}
                front-title={frontTitle}
                el-small-gap={this.elSmallGap}
                show-normal-price={showNormalPrice}
                language={this.language}
              />
            ) : null
          }
          {
            decisionAttrVisible ? <ItemDecisionAttr
              titleVisible={goodsNameVisible}
              attr-list={this.slicedDecisionAttr} /> : null
          }
          {/* 价格 */}
          {
            showPriceBelowGoodsName ? (
              <ProductItemPrices
                is-sold-out={isSoldOut}
                sale-price={salePrice}
                retail-price={retailPrice}
                show-camel-price={showCamelPrice}
                exclusive-promotion-price={exclusivePromotionPrice}
                has-feedback={hasFeedback}
                show-operatin-panel={showOperatinPanel}
                config={this.config}
                constant-data={this.constantData}
                cur-data={this.curData}
                pretreat-info={pretreatInfo}
                multi-column={multiColumn}
                show-best-deal={showBestDeal}
                below-goods-name={showPriceBelowGoodsName}
                show-normal-price={showNormalPrice}
                new-flash-promotion={newFlashPromotion}
                show-more-btn={showMoreBtn}
                shein-club-discount-value={sheinClubDiscountValue}
                show-small-shein-club-label={showSmallSheinClubLabel}
                bottom-info-right-gap={this.bottomInfoRightGap}
                flash-type-text={flashTypeText}
                new-flash-label-up-price-text={newFlashLabelUpPriceText}
                language={this.language}
                discount-info={discountInfo}
                vOn:openQuickAdd={this.handleOpenQuickAdd}
                vOn:clickMoreBtn={this.handleClickMoreBtn}
              >
                {/* 价格slot */}
                <template slot="price">{this.$slots.price}</template>
              </ProductItemPrices>
            ) : null
          }
          {
            showMyFlashHookPrice ? ((
              <ProductItemPricesMy
                is-sold-out={isSoldOut}
                sale-price={salePrice}
                retail-price={retailPrice}
                show-camel-price={showCamelPrice}
                exclusive-promotion-price={exclusivePromotionPrice}
                has-feedback={hasFeedback}
                show-operatin-panel={showOperatinPanel}
                config={this.config}
                constant-data={this.constantData}
                cur-data={this.curData}
                pretreat-info={pretreatInfo}
                multi-column={multiColumn}
                show-best-deal={showBestDeal}
                show-normal-price={showNormalPrice}
                new-flash-promotion={newFlashPromotion}
                show-more-btn={showMoreBtn}
                shein-club-discount-value={sheinClubDiscountValue}
                show-small-shein-club-label={showSmallSheinClubLabel}
                bottom-info-right-gap={this.bottomInfoRightGap}
                flash-type-text={flashTypeText}
                new-flash-label-up-price-text={newFlashLabelUpPriceText}
                language={this.language}
                discount-info={discountInfo}
                vOn:openQuickAdd={this.handleOpenQuickAdd}
                vOn:clickMoreBtn={this.handleClickMoreBtn}
                vOn:hookAddBagEvent={this.handleHookAddBagEvent}
              >
                {/* 价格slot */}
                <template slot="price">{this.$slots.price}</template>
              </ProductItemPricesMy>
            ) ) : null
          }
          {/* 自定义价格行 */}
          {
            customPriceLine
          }
          {/* 榜单 */}
          {
            rankingListVisible
              ? <ProductItemRankingList ranking-list={rankingList} /> 
              : null
          }
          {/* 星级评分+其他各种底部标签 */}
          {
            hasStarRating ? (
              this.curData && pretreatInfo && this.createComp ? (
                <ClientOnly>
                  <ProductItemStarRatingWithLabels
                    config={this.config}
                    cur-data={this.curData}
                    pretreat-info={pretreatInfo}
                    column={this.column}
                    constant-data={this.constantData}
                    item-width={itemWidth}
                    show-ranking-list-in={this.showRankingListInStarRating}
                    ranking-list={rankingList}
                    user-behavior-label-info={userBehaviorLabelInfo}
                    user-behavior-label-lang={userBehaviorLabelLang}
                    user-comment-keywords-label-lang={userCommentKeywordsLabelLang}
                    user-comment-keywords-label-info={userCommentKeywordsLabelInfo}
                    labels-show={labelsShow}
                    language={this.language}
                    goods-bottom-badge-style={goodsBottomBadgeStyle}
                    index={this.index}
                    hide-labels={showColorSwiper}
                    is-sold-out={isSoldOut}
                    show-selling-points-on-label={showSellingPointsOnLabel}
                    vOn:showCccTspBadge={this.handleShowCccTspBadge}
                    vOn:showQuickShip={this.handleShowQuickShip}
                  />
                </ClientOnly>
              ) : null
            ) : (
              this.hasStarComment 
                ? <ProductItemStarComment cur-data={this.curData} /> 
                : null
            )
          }
          {/* 色块 */}
          {
            showColorSwiper ? (
              <ProductItemColorSwiper
                item={this.item}
                index={this.index}
                cur-data={this.curData}
                config={this.config}
                constant-data={this.constantData}
                column={this.column}
                show-spu-img={needShowSpuImg}
                selected-color-id={this.selectedColorId}
                el-small-gap={this.elSmallGap}
                vOn:colorClick={this.handleColorClick}
              />
            ) : null
          }
          {/* 底部标签区域 */}
          { this.showPriceholder ? <PricePlaceHolder /> : null }
          <div class="product-item__label-ctn">
            <ClientOnly>
              <ProductItemBottomLabels 
                cur-data={ this.curData } 
                config={ this.config }
                language={ this.language } 
                index={ this.index } 
                constant-data={ this.constantData }
                item-width={itemWidth}
                pretreat-info={ pretreatInfo }
                create-comp={ this.createComp }
                is-sold-out={ isSoldOut }
                multi-column={ multiColumn }
                show-one-line={ this.showOneLineLabel }
                user-behavior-label-info={ userBehaviorLabelInfo }
                user-behavior-label-lang={ userBehaviorLabelLang }
                user-comment-keywords-label-lang={ userCommentKeywordsLabelLang }
                user-comment-keywords-label-info={ userCommentKeywordsLabelInfo }
                show-selling-points-on-label={ showSellingPointsOnLabel }
                labelsShow={ labelsShow }
                goods-bottom-badge-style={ goodsBottomBadgeStyle }
                has-star-rating={ hasStarRating }
                show-color-swiper={ showColorSwiper }
                has-normal-labels={ hasNormalLabels } 
                vOn:exposeSellingPoint={ this.handleExposeSellingPoint }
                vOn:showCccTspBadge={ this.handleShowCccTspBadge }
                vOn:showUserAct={ this.handleShowUserAct }
                vOn:clickSearchFilter={ ($event) =>  this.$emit('clickSearchFilter', $event) }
                vOn:showQuickShip={ this.handleShowQuickShip }
                vOn:showSearchFilter={ this.handleShowSearchFilter }
              />
            </ClientOnly>
          </div>
          {/* 商品底部slot */}
          {this.$slots.goodsBottom}
        </div>
        <ClientOnly>
          {/* 负反馈新手引导 */}
          {
            hasFeedback && !showAddBagAfterGoodsPrice && this.index === 0 && this.curOperateIndex === -1 
              ? <ProdcutItemFeedbackGuide language={this.language} /> 
              : null
          }
        </ClientOnly>
      </div>
    )
  },
}
</script>

<style lang="less">
.product-item-ctn {
  position: relative;
  &_disable {
    opacity: 0.5;
  }
  &_col-2 {
    width: 4.52rem;
  }
  &_col-23 {
    width: 4rem;
  }
  &_col-29 {
    width: 3.156rem;
  }
  &_col-3s {
    width: 2.6933rem;
  }
  &_col-3 {
    width: 2.9733rem;
  }
  &_col-3l {
    width: 3.08rem;
  }
  &_col-33 {
    width: 2.8rem;
  }
  &_col-33l {
    width: 2.96rem;
  }
  &_col-12l {
    width: 3.18rem;
  }
  &_col-35 {
    width: 2.42rem;
  }
  &_col-auto {
    width: auto;
  }
}
.product-item {
  &__main {
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    background-color: var(--productItemMainColor);
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0.5px /*rtl:ignore*/;
    //   width: 200%;
    //   height: 200%;
    //   border: 0.5px solid transparent;
    //   background-color: #e5e5e5;
    //   transform: scale(0.5);
    //   transform-origin: top left /*rtl:ignore*/;
    // }
    // height: 0;
    // padding-bottom: 133%;
    // background-color: #f5f5f5;
    // &_crop-100 {
    //   padding-bottom: 100%;
    // }
    // &_crop-120 {
    //   padding-bottom: 120%;
    // }
  }
  &__img-bottomright {
    position: absolute;
    right: 0.1067rem;
    bottom: 0.1067rem;
  }
  &__bottom-info {
    margin-top: 0.16rem;
    &_soldout {
      & > :not(.product-item__colors){
        opacity: .4;
      }
    }
    &_smaller-gap {
      margin-top: 0.0533rem;
    }
  }
  &__label-ctn {
    padding-top: 0.1067rem;
  }
}
// .waterfall-column:nth-of-type(2) {
//   .product-item__main {
//     &::before {
//       left: unset /*rtl:ignore*/;
//       right: 0.5px /*rtl:ignore*/;
//       transform-origin: top right /*rtl:ignore*/;
//     }
//   }
// }
</style>
